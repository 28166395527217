import { IdgStart } from './idg-start';
import { IdgSteps } from './idg-steps';
import { IdgSummary } from './idg-summary';
import { useIdgContext } from './context/IdgContext';
import { initialValues, key, steps } from './data';
import { useForm as useLibForm } from '@/lib/hooks/use-form';
import { useParams } from 'next/navigation';
import { useForm } from 'react-hook-form';

/* === SURG - Informed Discussion Guide === */

export const Idg = () => {
    const { activeStep, formRef, completeForm, formStatus } = useIdgContext();
    const { locale: language } = useParams();
    const { handleSubmit, isPending } = useLibForm({
        form: 'form_20270',
        ref: formRef,
    });

    function handleFormCompletion(values) {
        try {
            const getAnswer = (question) => {
                try {
                    return key[parseInt(values[question].at(-1)) - 1];
                } catch (error) {
                    console.log(error);
                }
            };

            const formResults = steps.map((step) => {
                return {
                    title: step.subTitle,
                    questions: step.questions.map((question) => {
                        return {
                            title: question.label,
                            answer:
                                step.style !== 'standard'
                                    ? getAnswer(`q${question.id}`)
                                    : values[`q${question.id}`],
                        };
                    }),
                };
            });

            if (values.q14 === 'false') {
                let tissue = [];
                let mech = [];
                let valve = null;

                const tissuePattern = /^tissue\d+$/;
                const mechPattern = /^mech\d+$/;

                for (let choice in values) {
                    if (tissuePattern.test(values[choice])) {
                        tissue.push('x');
                    }
                    if (mechPattern.test(values[choice])) {
                        mech.push('x');
                    }
                }

                if (tissue.length > mech.length) {
                    valve = 'tissue';
                }

                if (tissue.length < mech.length) {
                    valve = 'mechanical';
                }

                completeForm({ formResults, valve });
            } else {
                const form = {
                    FirstName: values.FirstName,
                    LastName: values.LastName,
                    Email: values.Email,
                    pictureURL: JSON.stringify(formResults),
                };
                handleSubmit('marketo', form);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const form = useForm({ defaultValues: initialValues });

    const data = form.watch();

    return (
        <div ref={formRef}>
            {activeStep === 0 && <IdgStart />}

            {activeStep > 0 && (
                <>
                    {formStatus === 'incomplete' && (
                        <form
                            onSubmit={form.handleSubmit(handleFormCompletion)}
                            className="form form--lg"
                        >
                            <section className="section">
                                <div className="container">
                                    <IdgSteps
                                        form={form}
                                        isPending={isPending}
                                        language={language}
                                        initialValues={initialValues}
                                        data={data}
                                    />
                                </div>
                            </section>
                        </form>
                    )}
                    {formStatus === 'complete' && (
                        <IdgSummary data={data} form={form} />
                    )}
                </>
            )}
        </div>
    );
};
