import { Button } from '@/components/button';
import { Survey } from '@/blocks/idg/survey';
import { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { merriweather_sans, noto_sans_jp } from '@/lib/utils/fonts';
import { useIdgContext } from './context/IdgContext';
import { useParams } from 'next/navigation';
import { Radio } from '@/blocks/form/elements/radio';

export const IdgSummary = ({ data, form }) => {
    const { steps, formResults, valve } = useIdgContext();
    const { locale } = useParams();

    const answers = useRef();
    const handlePrint = useReactToPrint({
        contentRef: answers,
        documentTitle: 'Informed Discussion Guide Summary',
    });

    return (
        <>
            {locale === 'default' && (
                <>
                    <section className="section bg-beige-light">
                        <div className="container">
                            {valve && (
                                <>
                                    <div className="eyebrow">
                                        Based on your answers,
                                    </div>
                                    <h2>
                                        A {valve} valve may be more suitable for
                                        you.
                                    </h2>
                                </>
                            )}
                            <div className="lead mb-14">
                                Discuss your preferences further with your
                                cardiologist or heart surgeon.
                            </div>
                            <Button
                                cta_link="/patients-care-partners/heart-valve-disease-information/surgical/find-a-surgeon"
                                icon_position={'right'}
                                icon="fa-solid fa-arrow-right"
                            >
                                Find a RESILIA valve surgeon
                            </Button>
                        </div>
                    </section>
                    <section className="section">
                        <div className="container">
                            <div className="lead mb-8">
                                Print your answers and discuss your preferences
                                further with your cardiologist or heart surgeon.
                            </div>
                            <Button btn_style="tertiary" trigger={handlePrint}>
                                Print answers
                            </Button>
                        </div>
                    </section>
                </>
            )}
            {locale === 'in' && (
                <section className="section bg-beige-light mb-24">
                    <div className="container">
                        {valve && (
                            <>
                                <div className="eyebrow">
                                    Based on your answers,
                                </div>
                                <h2>
                                    A {valve} valve may be more suitable for
                                    you.
                                </h2>
                            </>
                        )}
                        <div className="lead mb-8">
                            Print your answers and discuss your preferences
                            further with your cardiologist or heart surgeon.
                        </div>
                        <Button
                            icon="fa-solid fa-arrow-right"
                            icon_position="right"
                            trigger={handlePrint}
                        >
                            Print answers
                        </Button>
                    </div>
                </section>
            )}
            <div className="pe-none">
                <div className="container">
                    {steps.map((step) => {
                        return (
                            <div key={step.id} className="mb-14">
                                {step.subTitle && (
                                    <h4 className="text-maroon mb-8">
                                        {step.subTitle}
                                    </h4>
                                )}
                                {step.style === 'likert' && (
                                    <Survey
                                        form={form}
                                        questions={step.questions}
                                        headings={step.headings}
                                        tabIndex="-1"
                                    />
                                )}
                                {step.style === 'standard' &&
                                    step.questions.map((question) => {
                                        return (
                                            <div
                                                key={question.id}
                                                className="mb-8"
                                            >
                                                <h5 className="mb-4">
                                                    {question.label}
                                                </h5>

                                                <Radio
                                                    options={question.options}
                                                    name={`q${question.id}`}
                                                    register={form.register}
                                                    type="radio"
                                                    validation={{
                                                        required: false,
                                                        pattern: null,
                                                    }}
                                                    disabled
                                                    errors={
                                                        form.formState.errors
                                                    }
                                                    data={data}
                                                    reset={null}
                                                />
                                            </div>
                                        );
                                    })}
                            </div>
                        );
                    })}
                </div>
            </div>
            <section className="section section--top-none bg-white">
                <div className="container">
                    <Button btn_style="tertiary" trigger={handlePrint}>
                        Print answers
                    </Button>
                </div>
            </section>

            <div className="d-none">
                <div
                    ref={answers}
                    className={`${merriweather_sans.variable} ${noto_sans_jp.variable} font-en`}
                >
                    <table
                        style={{
                            border: 'none',
                            borderCollapse: 'collapse',
                        }}
                    >
                        <thead>
                            <tr>
                                <th>
                                    <div
                                        style={{
                                            marginLeft: '.4in',
                                            marginBottom: '.3in',
                                        }}
                                    >
                                        <div
                                            className="bg-crimson"
                                            style={{
                                                height: '1.9in',
                                                paddingTop: '.5in',
                                                textAlign: 'left',
                                                paddingLeft: '.34in',
                                                marginLeft: '.4in',
                                            }}
                                        >
                                            <h2>
                                                Informed Discussion Guide
                                                Summary
                                            </h2>
                                        </div>
                                        <div
                                            className="bg-crimson"
                                            style={{
                                                width: '.4in',
                                                height: '.4in',
                                            }}
                                        ></div>
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <div
                                        style={{
                                            paddingLeft: '1.1in',
                                            paddingRight: '.5in',
                                        }}
                                    >
                                        <p className="small">
                                            Below is a summary of your responses
                                            from the Informed Discussion Guide
                                            you filled out on Edwards.com. You
                                            may use it to help support your
                                            converstion with your doctor about
                                            the differences between mechanical
                                            and tissue valves. It reflects your
                                            goals, values, preferences and
                                            questions based on the answers you
                                            provided. Together, with your
                                            doctor, you can determine which type
                                            of valve may be better suited for
                                            you.
                                        </p>
                                        {formResults.map((result, key) => {
                                            return (
                                                <div
                                                    className="mb-4"
                                                    key={key}
                                                    style={{
                                                        maxWidth: '80%',
                                                    }}
                                                >
                                                    {result.questions.some(
                                                        (question) =>
                                                            question.answer
                                                    ) && (
                                                        <h4
                                                            className="text-maroon mb-2"
                                                            style={{
                                                                breakAfter:
                                                                    'avoid-page',
                                                            }}
                                                        >
                                                            {result.title}
                                                        </h4>
                                                    )}
                                                    {result.questions.map(
                                                        (question, key) => {
                                                            if (
                                                                !question.answer
                                                            )
                                                                return null;
                                                            return (
                                                                <div
                                                                    className="mb-4"
                                                                    key={key}
                                                                >
                                                                    <h5
                                                                        className="small mb-0 text-black"
                                                                        style={{
                                                                            breakAfter:
                                                                                'avoid-page',
                                                                        }}
                                                                    >
                                                                        {
                                                                            question.title
                                                                        }
                                                                    </h5>
                                                                    <p className="small">
                                                                        {
                                                                            question.answer
                                                                        }
                                                                    </p>
                                                                </div>
                                                            );
                                                        }
                                                    )}
                                                </div>
                                            );
                                        })}
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <td>
                                    <div
                                        style={{
                                            height: '250px',
                                            paddingLeft: '1.1in',
                                            paddingRight: '.5in',
                                            marginTop: '100px',
                                        }}
                                    >
                                        <div style={{ display: 'flex' }}>
                                            <div
                                                style={{ marginRight: '64px' }}
                                            >
                                                {locale === 'default' && (
                                                    <p className="x-small mb-4">
                                                        Please visit the{' '}
                                                        <a href="http://www.edwards.com/patients-care-partners/heart-valve-disease-information/surgical/find-a-surgeon">
                                                            find a surgeon
                                                        </a>{' '}
                                                        page on edwards.com for
                                                        a list of hospitals that
                                                        perform aortic and
                                                        mitral valve
                                                        replacements.
                                                    </p>
                                                )}
                                                <p className="x-small mb-4">
                                                    <strong>
                                                        CAUTION: Federal (United
                                                        States) law restricts
                                                        these devices to sale by
                                                        or on the order of a
                                                        physician.
                                                    </strong>
                                                </p>
                                                <p className="x-small mb-4">
                                                    Edwards, Edwards
                                                    Lifesciences, and the
                                                    stylized E logo are
                                                    trademarks of Edwards
                                                    Lifesciences Corporation or
                                                    its affiliates. All other
                                                    trademarks are the property
                                                    of their respective owners.
                                                </p>
                                                <p className="x-small mb-4">
                                                    © {new Date().getFullYear()}{' '}
                                                    Edwards Lifesciences
                                                    Corporation. All rights
                                                    reserved. PP--US-8214 v1.0
                                                </p>
                                                <p className="x-small">
                                                    Edwards Lifesciences • One
                                                    Edwards Way, Irvine CA 92614
                                                    USA • edwards.com
                                                </p>
                                            </div>
                                            <img
                                                style={{ marginTop: 'auto' }}
                                                src={'/logo.svg'}
                                                alt="Edwards Lifesciences Logo"
                                            />
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </>
    );
};
