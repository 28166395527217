export const Survey = ({ questions, headings, tabIndex, form }) => {
    if (!form) return;
    return (
        <div className="survey">
            <div className="survey__head">
                {headings.map((heading) => {
                    return (
                        <div key={heading.id} className="survey__heading">
                            {heading.label}
                        </div>
                    );
                })}
            </div>
            <div className="survey__body">
                {questions.map((question) => {
                    return (
                        <div key={question.id} className="survey__item">
                            <div className="survey__label">
                                {question.label}
                            </div>
                            <div className="survey__scale">
                                <div className="survey__scale-item">
                                    {headings[0].label}
                                </div>
                                <div className="survey__scale-item">
                                    {headings[headings.length - 1].label}
                                </div>
                            </div>
                            <div className="survey__options">
                                {question.options.map((option) => {
                                    return (
                                        <div
                                            key={option.value}
                                            className="survey__option"
                                        >
                                            <label className="survey__option-label">
                                                <input
                                                    className="survey__option-input"
                                                    type="radio"
                                                    {...form.register(
                                                        `q${question.id}`
                                                    )}
                                                    value={option.value}
                                                    tabIndex={tabIndex}
                                                />
                                                <div className="survey__option-radio">
                                                    {option.label}
                                                </div>
                                            </label>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};
