import {
    createContext,
    useContext,
    useReducer,
    useRef,
    useCallback,
} from 'react';
import { useWindowDimensions } from '@/lib/hooks/use-window-dimensions';
import { breakpoints } from '@/assets/data/breakpoints';
import { steps } from '../data';

const IdgContext = createContext();

const initialValue = {
    activeStep: 0,
    steps,
    formStatus: 'incomplete',
    valve: null,
    formResults: null,
};

const actions = {
    INCREASE_STEP: 'INCREASE_STEP',
    DECREASE_STEP: 'DECREASE_STEP',
    COMPLETE_FORM: 'COMPLETE_FORM',
};

function reducer(state, action) {
    switch (action.type) {
        case actions.INCREASE_STEP:
            return { ...state, activeStep: state.activeStep + 1 };
        case actions.DECREASE_STEP:
            return { ...state, activeStep: state.activeStep - 1 };
        case actions.COMPLETE_FORM:
            return {
                ...state,
                formStatus: 'complete',
                formResults: action.payload.formResults,
                valve: action.payload.valve,
            };
        default:
            return state;
    }
}

export function IdgProvider({ children }) {
    const [value, dispatch] = useReducer(reducer, initialValue);
    const { width } = useWindowDimensions();
    const formRef = useRef(null);

    const resetFocus = useCallback(() => {
        const breakpoint = width > breakpoints.desktop ? 86 : 48;
        const offset = formRef.current.offsetTop - breakpoint;
        window.scrollTo({
            top: offset,
        });
    }, [width]);

    function increaseStep() {
        dispatch({ type: actions.INCREASE_STEP });
        resetFocus();
    }

    function decreaseStep() {
        dispatch({ type: actions.DECREASE_STEP });
        resetFocus();
    }

    function completeForm(value) {
        dispatch({ type: actions.COMPLETE_FORM, payload: value });
    }

    return (
        <IdgContext.Provider
            value={{
                ...value,
                formRef,
                increaseStep,
                decreaseStep,
                completeForm,
            }}
        >
            {children}
        </IdgContext.Provider>
    );
}

export function useIdgContext() {
    const context = useContext(IdgContext);
    if (!context)
        throw new Error('Please use within the appropriate context provider');
    return context;
}
