'use client';

import { Suspense } from 'react';
import { IdgProvider } from './context/IdgContext';
import { Idg } from './idg';

export const IDG = () => {
    return (
        <IdgProvider>
            <Suspense>
                <Idg />
            </Suspense>
        </IdgProvider>
    );
};
