export let initialValues = {
    q1: '',
    q2: '',
    q3: '',
    q4: '',
    q5: '',
    q6: '',
    q7: '',
    q8: '',
    q9: '',
    q10: '',
    q11: '',
    q12: '',
    q13: '',
    q14: '',
    FirstName: '',
    LastName: '',
    Email: '',
    Country: 'United States',
    State: '',
    multipurposeOptIn: [],
};

const headings = [
    { label: 'Strongly disagree', id: 1 },
    { label: 'Disagree', id: 2 },
    { label: 'Neither agree nor disagree', id: 3 },
    { label: 'Agree', id: 4 },
    { label: 'Strongly agree', id: 5 },
];

const tissueMechScale = [
    { label: '1', value: 'tissue1', id: 1 },
    { label: '2', value: 'tissue2', id: 2 },
    { label: '3', value: 'neutral3', id: 3 },
    { label: '4', value: 'mech4', id: 4 },
    { label: '5', value: 'mech5', id: 5 },
];

const mechTissueScale = [
    { label: '1', value: 'mech1', id: 1 },
    { label: '2', value: 'mech2', id: 2 },
    { label: '3', value: 'neutral3', id: 3 },
    { label: '4', value: 'tissue4', id: 4 },
    { label: '5', value: 'tissue5', id: 5 },
];

const lead = 'Your feelings about your treatment options matter';
const description =
    'Using a scale from 1 to 5, where 1 means “Strongly disagree” and 5 means “Strongly agree,” please indicate how much you agree or disagree with the following statements.';

export const steps = [
    {
        title: 'Step 1 of 4: Discuss your feelings and goals',
        lead,
        description,
        subTitle: 'I am comfortable with',
        button: 'Continue',
        id: 1,
        headings,
        style: 'likert',
        questions: [
            {
                label: 'Hearing my valve clicking',
                options: tissueMechScale,
                id: 1,
            },
            {
                label: 'Taking blood thinners for the rest of my life',
                options: tissueMechScale,
                id: 2,
            },
            {
                label: 'Undergoing another open heart surgery if another replacement is required',
                options: tissueMechScale,
                id: 3,
            },
        ],
    },
    {
        title: 'Step 2 of 4: Discuss your feelings and goals (continued)',
        subTitle: 'Is it important to me',
        button: 'Continue',
        id: 2,
        headings,
        style: 'likert',
        questions: [
            {
                label: 'To maintain an active lifestyle',
                options: mechTissueScale,
                id: 4,
            },
            {
                label: 'To receive a valve with longevity',
                options: tissueMechScale,
                id: 5,
            },
            {
                label: 'To receive a less invasive procedure if another replacement is required',
                options: mechTissueScale,
                id: 6,
            },
        ],
    },
    {
        title: 'Step 3 of 4: Discuss your feelings and goals (continued)',
        subTitle: 'It will be challenging for me',
        button: 'Make a decision',
        id: 3,
        headings,
        style: 'likert',
        questions: [
            {
                label: 'To have a valve with an audible clicking sound',
                options: mechTissueScale,
                id: 7,
            },
            {
                label: 'To be monitored regularly while taking blood thinner so that the doctor can make changes to medicine dose',
                options: mechTissueScale,
                id: 8,
            },
            {
                label: 'To have daily dietary restrictions',
                options: mechTissueScale,
                id: 9,
            },
            {
                label: 'To have an increased pregnancy risk',
                options: mechTissueScale,
                id: 10,
            },
            {
                label: 'To know my valve may deteriorate over time',
                options: mechTissueScale,
                id: 11,
            },
        ],
    },
    {
        title: 'Step 4 of 4: Making a decision (continued)',
        description:
            "Congratulations, you've taken the first step in making a decision about whether you should get a tissue or mechanical valve replacement. Below are the differences to consider when evaluating your options.",
        subTitle: '',
        id: 4,
        button: 'Your summary',
        style: 'standard',
        questions: [
            {
                label: 'Based on your answers to the previous questions, which next step are you leaning toward?',
                options: [
                    {
                        label: 'A mechanical valve',
                        value: 'A mechanical valve',
                    },
                    { label: 'A tissue valve', value: 'A tissue valve' },
                    { label: 'Undecided', value: 'Undecided' },
                ],
                id: 12,
            },
            {
                label: 'If you have decided, how confident are you in your decision?',
                options: [
                    { label: 'Not confident', value: 'Not confident' },
                    {
                        label: 'Somewhat confident',
                        value: 'Somewhat confident',
                    },
                    { label: 'Undecided', value: 'Undecided' },
                ],
                id: 13,
            },
        ],
    },
    {
        title: 'Get a copy of your summary to use when talking with your doctor',
        lead: '',
        description:
            'Your Informed Discussion Guide will be a valuable resource in conversations with your Care Team. ',
        subTitle: '',
        id: 5,
        button: 'Submit',
        style: 'submission',
        questions: [
            {
                label: 'Please choose one*',
                options: [
                    {
                        label: 'Send my summary and more information on Edwards Lifesciences†',
                        value: 'true',
                    },
                    {
                        label: 'No thank you, just take me to my answers',
                        value: 'false',
                    },
                ],
                id: 14,
            },
        ],
    },
];

export const us_states = {
    label: 'State',
    options: [
        {
            value: 'AK',
            label: 'AK',
        },
        {
            value: 'AL',
            label: 'AL',
        },
        {
            value: 'AR',
            label: 'AR',
        },
        {
            value: 'AZ',
            label: 'AZ',
        },
        {
            value: 'CA',
            label: 'CA',
        },
        {
            value: 'CO',
            label: 'CO',
        },
        {
            value: 'CT',
            label: 'CT',
        },
        {
            value: 'DE',
            label: 'DE',
        },
        {
            value: 'FL',
            label: 'FL',
        },
        {
            value: 'GA',
            label: 'GA',
        },
        {
            value: 'HI',
            label: 'HI',
        },
        {
            value: 'IA',
            label: 'IA',
        },
        {
            value: 'ID',
            label: 'ID',
        },
        {
            value: 'IL',
            label: 'IL',
        },
        {
            value: 'IN',
            label: 'IN',
        },
        {
            value: 'KS',
            label: 'KS',
        },
        {
            value: 'KY',
            label: 'KY',
        },
        {
            value: 'LA',
            label: 'LA',
        },
        {
            value: 'MA',
            label: 'MA',
        },
        {
            value: 'MD',
            label: 'MD',
        },
        {
            value: 'ME',
            label: 'ME',
        },
        {
            value: 'MI',
            label: 'MI',
        },
        {
            value: 'MN',
            label: 'MN',
        },
        {
            value: 'MO',
            label: 'MO',
        },
        {
            value: 'MS',
            label: 'MS',
        },
        {
            value: 'MT',
            label: 'MT',
        },
        {
            value: 'NC',
            label: 'NC',
        },
        {
            value: 'ND',
            label: 'ND',
        },
        {
            value: 'NE',
            label: 'NE',
        },
        {
            value: 'NH',
            label: 'NH',
        },
        {
            value: 'NJ',
            label: 'NJ',
        },
        {
            value: 'NM',
            label: 'NM',
        },
        {
            value: 'NV',
            label: 'NV',
        },
        {
            value: 'NY',
            label: 'NY',
        },
        {
            value: 'OH',
            label: 'OH',
        },
        {
            value: 'OK',
            label: 'OK',
        },
        {
            value: 'OR',
            label: 'OR',
        },
        {
            value: 'PA',
            label: 'PA',
        },
        {
            value: 'RI',
            label: 'RI',
        },
        {
            value: 'SC',
            label: 'SC',
        },
        {
            value: 'SD',
            label: 'SD',
        },
        {
            value: 'TN',
            label: 'TN',
        },
        {
            value: 'TX',
            label: 'TX',
        },
        {
            value: 'UT',
            label: 'UT',
        },
        {
            value: 'VA',
            label: 'VA',
        },
        {
            value: 'VT',
            label: 'VT',
        },
        {
            value: 'WA',
            label: 'WA',
        },
        {
            value: 'WI',
            label: 'WI',
        },
        {
            value: 'WV',
            label: 'WV',
        },
        {
            value: 'WY',
            label: 'WY',
        },
    ],
    placeholder: 'Select...',
};

export const key = [
    'Strongly disagree',
    'Disagree',
    'Neither agree nor disagree',
    'Agree',
    'Strongly Agree',
];
