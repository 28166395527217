import { Button } from '@/components/button';
import { useIdgContext } from './context/IdgContext';

export const IdgStart = () => {
    const { increaseStep } = useIdgContext();
    return (
        <section className="section">
            <div className="container">
                <h2>The Informed Discussion Guide</h2>
                <p className="lead">
                    Surgical aortic or mitral valve replacement: Choosing the
                    right valve for you
                </p>
                <p>
                    When moving forward with surgery, it's natural to have
                    questions or feel overwhelmed as you begin to review your
                    options. This Informed Discussion Guide is a tool to help
                    you have productive conversations with your doctor as you
                    consider the differences between mechanical and tissue
                    valves and assess your goals following surgery.
                </p>
                <p>
                    This guide will take your health, values, preferences, and
                    questions into consideration, which are important when
                    seeking out the valve that's right for you.{' '}
                    <strong>
                        It will take approximately 5 minutes to complete.
                    </strong>
                </p>
                <p>
                    Share your results with your doctor, so that in addition to
                    age guidelines and your anatomy, your feelings and goals
                    will be taken into account. Make a decision on the best
                    valve for you, alongside your doctor.
                </p>
                <Button
                    trigger={increaseStep}
                    icon_position={'right'}
                    icon="fa-solid fa-arrow-right"
                >
                    Launch the guide
                </Button>
            </div>
        </section>
    );
};
