import { Fragment } from 'react';
import { Button } from '@/components/button';
import { Survey } from '@/blocks/idg/survey';
import { Link } from '@/components/link';
import { Text } from '@/blocks/form/elements/text';
import { Select } from '@/blocks/form/elements/select';
import { Checkbox } from '@/blocks/form/elements/checkbox';
import { Radio } from '@/blocks/form/elements/radio';
import { us_states } from './data';
import { useIdgContext } from './context/IdgContext';

export const IdgSteps = ({ isPending, form, data }) => {
    const { increaseStep, decreaseStep, activeStep, steps } = useIdgContext();
    return (
        <div>
            {steps.map((step) => {
                return (
                    step.id === activeStep && (
                        <div key={step.id}>
                            <h3>{step.title}</h3>
                            {step.lead && <p className="lead">{step.lead}</p>}
                            <p className="mb-14">{step.description}</p>
                            {step.subTitle && (
                                <h4 className="text-maroon mb-8">
                                    {step.subTitle}
                                </h4>
                            )}
                            {step.style === 'likert' && (
                                <Survey
                                    form={form}
                                    questions={step.questions}
                                    headings={step.headings}
                                />
                            )}
                            {step.style === 'standard' &&
                                step.questions.map((question) => {
                                    return (
                                        <div key={question.id} className="mb-8">
                                            <h5 className="mb-4">
                                                {question.label}
                                            </h5>
                                            <Radio
                                                options={question.options}
                                                name={`q${question.id}`}
                                                register={form.register}
                                                type="radio"
                                                validation={{
                                                    required: false,
                                                    pattern: null,
                                                }}
                                                errors={form.formState.errors}
                                                data={data}
                                                reset={null}
                                            />
                                        </div>
                                    );
                                })}
                            {step.style === 'submission' &&
                                step.questions.map((question) => {
                                    return (
                                        <Fragment key={question.id}>
                                            <div
                                                key={question.id}
                                                className="mb-8"
                                            >
                                                <h5 className="mb-4">
                                                    {question.label}
                                                </h5>
                                                <Radio
                                                    options={question.options}
                                                    name={`q${question.id}`}
                                                    register={form.register}
                                                    type="radio"
                                                    validation={{
                                                        required:
                                                            'Please choose one option',
                                                        pattern: null,
                                                    }}
                                                    errors={
                                                        form.formState.errors
                                                    }
                                                    data={data}
                                                    reset={null}
                                                />
                                            </div>
                                            {data.q14 === 'true' && (
                                                <>
                                                    <div className="grid-md-12 mb-6">
                                                        <Text
                                                            label="First Name"
                                                            name={'FirstName'}
                                                            register={
                                                                form.register
                                                            }
                                                            width="full"
                                                            type="text"
                                                            validation={{
                                                                required:
                                                                    'First Name is required',
                                                                pattern: null,
                                                            }}
                                                            errors={
                                                                form.formState
                                                                    .errors
                                                            }
                                                            data={data}
                                                            reset={null}
                                                        />
                                                        <Text
                                                            label="Last Name"
                                                            name={'LastName'}
                                                            width="full"
                                                            register={
                                                                form.register
                                                            }
                                                            type="text"
                                                            validation={{
                                                                required:
                                                                    'Last Name is required',
                                                                pattern: null,
                                                            }}
                                                            errors={
                                                                form.formState
                                                                    .errors
                                                            }
                                                            data={data}
                                                            reset={null}
                                                        />
                                                        <Text
                                                            label="Email Address"
                                                            name={'Email'}
                                                            width="full"
                                                            type="email"
                                                            register={
                                                                form.register
                                                            }
                                                            validation={{
                                                                required:
                                                                    'Email address is required',
                                                                pattern: null,
                                                            }}
                                                            errors={
                                                                form.formState
                                                                    .errors
                                                            }
                                                            data={data}
                                                            reset={null}
                                                        />
                                                        <Select
                                                            name="State"
                                                            label="State"
                                                            options={
                                                                us_states.options
                                                            }
                                                            width="full"
                                                            control={
                                                                form.control
                                                            }
                                                            register={
                                                                form.register
                                                            }
                                                            validation={{
                                                                required:
                                                                    'State is required',
                                                                pattern: null,
                                                            }}
                                                            errors={
                                                                form.formState
                                                                    .errors
                                                            }
                                                            data={data}
                                                            reset={null}
                                                        />
                                                    </div>
                                                    <MultiPurposeOptIn
                                                        form={form}
                                                        data={data}
                                                    />
                                                </>
                                            )}
                                            <p className="small">
                                                * Your privacy matters. We will
                                                use your email to send you a
                                                copy of the information you saw
                                                today, as well as a reminder in
                                                2 months (if requested) to see
                                                if additional helpful
                                                information can be shared with
                                                you. By selecting the "Submit"
                                                button, you agree to the Edwards
                                                Lifesciences{' '}
                                                <Link
                                                    className={'text-grey-80'}
                                                    href="/legal/privacy-policy"
                                                >
                                                    privacy policy
                                                </Link>
                                                .
                                            </p>
                                            <p className="small">
                                                † By selecting this option, you
                                                are agreeing to receive news
                                                updates from Edwards
                                                Lifesciences. By providing your
                                                personal information, you agree
                                                to allow Edwards Lifesciences
                                                and its agents to use this
                                                information to communicate with
                                                you in the future, including
                                                information about services,
                                                events and programs. Edwards
                                                Lifesciences and its agents will
                                                not sell, rent or otherwise
                                                distribute your name and any
                                                personally identifiable
                                                information outside of Edwards
                                                Lifesciences and its agents.
                                                Edwards Lifesciences will use
                                                your information in accordance
                                                with Edwards Lifesciences{' '}
                                                <Link
                                                    className={'text-grey-80'}
                                                    href="/legal/privacy-policy"
                                                >
                                                    privacy policy
                                                </Link>
                                                .
                                            </p>
                                        </Fragment>
                                    );
                                })}
                            <div className="mt-6 survey__buttons">
                                {activeStep !== 0 && (
                                    <Button
                                        trigger={decreaseStep}
                                        className="mr-2"
                                        btn_style="ghost"
                                    >
                                        Back
                                    </Button>
                                )}
                                {step.style !== 'submission' && (
                                    <Button
                                        trigger={increaseStep}
                                        icon_position={'right'}
                                        icon="fa-solid fa-arrow-right"
                                    >
                                        {step.button}
                                    </Button>
                                )}
                                {step.style === 'submission' && (
                                    <Button type="submit" disabled={isPending}>
                                        {isPending
                                            ? 'Submitting...'
                                            : step.button}
                                    </Button>
                                )}
                            </div>
                        </div>
                    )
                );
            })}
        </div>
    );
};

const MultiPurposeOptIn = ({ data, form }) => {
    const condition =
        data.Country === 'United States' &&
        ['CO', 'CT', 'VA'].includes(data.State);
    return (
        condition && (
            <>
                <p className="small">
                    I agree that the information I've provided above, some of
                    which may be considered to reveal sensitive personal
                    information such as any information about cardiovascular or
                    other health care conditions, may be used by Edwards (and
                    its contracted third parties) to enroll me into this
                    program, which will provide me with information and
                    opportunities (“Program”). Edwards requires your sensitive
                    personal information to determine your eligibility for the
                    Program and associated benefits and subsequent
                    communications. For more information about how we will use
                    and disclose your information please review our{' '}
                    <Link href="/legal/privacy-policy">Privacy Policy</Link>.
                </p>
                <Checkbox
                    parse_rich_text
                    options={[
                        {
                            label: "<p class='small'>By clicking this box, I further agree that the information I've provided above, including any sensitive information, may be used to provide me with information, offers, and marketing materials for Cardiovascular and related diseases and associated treatments, products and services, in addition to those in the Program. Edwards will not sell or transfer my name or contact information to any third party for its own marketing use. I understand that I may opt-out from such communications at any time by clicking the unsubscribe link in any email from Edwards and opt-out of the program by calling <span style='display:inline-block'>(888) 570-4014</span>.</p>",
                            value: true,
                        },
                    ]}
                    register={form.register}
                    name="multipurposeOptIn"
                    validation={{
                        required: false,
                        pattern: null,
                    }}
                    type="checkbox"
                    errors={form.formState.errors}
                    data={data}
                    reset={null}
                />
            </>
        )
    );
};
